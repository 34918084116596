// Defining color scheme 
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    gold: {
      main: '#c39c5d',
      contrastText: '#ffffff', 
    },
    red: {
      main: '#c72127',
      contrastText: '#ffffff',
    },
    navy: {
      main: '#2b4265',
      contrastText: '#ffffff',
    },
    silver: {
      main: '#e0e0e0',
      contrastText: '#000000',
    },
    charcoal: {
      main: '#2b2b2b',
      contrastText: '#ffffff',
    },
    snow: {
      main: '#fafbfd',
      contrastText: '#000000',
    },
  },

  typography: {
    fontFamily: 'Open Sans, sans-serif',
    h1: { fontWeight: 800 }, // ExtraBold for headlines
    h2: { fontWeight: 600 },
    h3: { fontWeight: 400 },
    h4: { fontWeight: 800 },
    h5: { fontWeight: 800 },
    h6: { fontWeight: 800 },
    body1: { fontWeight: 400 }, // Regular for body text
    body2: { fontWeight: 400 },
    caption: {
      fontStyle: 'italic', // Italic for captions
      fontWeight: 400,
    },
  },
});

export default theme;
