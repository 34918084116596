import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme.js';
import MyRoutes from './routes';
import Preloader from './components/Preloader.js';
import { LoadingProvider } from './hooks/LoadingContext.js';

function App() {
  const [loading, setLoading] = useState(true);
  const [startAnimations, setStartAnimations] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      setStartAnimations(true); 
    }, 800);

    return () => clearTimeout(timer);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LoadingProvider> {/* Wrap your components with LoadingProvider */}
        <Preloader isVisible={loading} />
        <MyRoutes startAnimations={startAnimations} />
      </LoadingProvider>
    </ThemeProvider>
  );
}

export default App;

