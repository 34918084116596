import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useLoading } from './LoadingContext'; // Adjust the import path as needed

const RouterListener = () => {
  const location = useLocation();
  const { setLoading } = useLoading(); // Use the loading state hook

  useEffect(() => {
    if (location.pathname === '/') {
      return;
    }
    setLoading(true); // Show preloader on route change
    const timer = setTimeout(() => setLoading(false), 800); // Hide preloader after a delay
    return () => clearTimeout(timer);
  }, [location, setLoading]);

  return null; // This component does not render anything
};

export default RouterListener;
