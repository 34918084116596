// Custom hook to handle the current state of the viewport for animations
import { useState, useEffect, useRef } from 'react';

const useViewportAnimation = (animationClassName, delay = 200) => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const currentRef = ref.current;
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setTimeout(() => {
                        setIsVisible(true);
                    }, delay);
                }
            },
            { rootMargin: '0px' }
        );

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [delay]);

    const animationClass = isVisible ? animationClassName : '';
    return [ref, animationClass];
};

export default useViewportAnimation;

