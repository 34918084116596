import React from 'react';
import '../stylesheets/preloader.scss'; 

const Preloader = ({ isVisible }) => {

    return (
        <div className={`preloader ${!isVisible ? 'fadeOut' : ''}`}>
            <div className="pulse-line-container">
                <svg
                    id="Layer_2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 592.63 144.23"
                >
                    <g id="Layer_1-2">
                        <polyline
                            className="cls-1"
                            points="3.5 80.17 115.9 80.17 131.36 43.44 150.69 102.72 171.95 3.5 189.99 140.73 204.81 79.52 589.13 78.88"
                        />
                    </g>
                </svg>
            </div>
        </div>
    );
};

export default Preloader;
