import React from "react";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Grid,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "../../stylesheets/animations.scss";
import useViewportAnimation from "../../hooks/useViewportAnimation";

const ModuleCard = ({ title, image, description, animationDelay }) => {
  const [ref, animationClass] = useViewportAnimation(
    "fadeInUp",
    animationDelay
  );

  const cardStyle = {
    height: "350px",
    borderRadius: "15px",
    backgroundColor: "#ececec",
    margin: "30px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    opacity: 0,
  };

  const imageStyle = {
    height: "80px",
    marginRight: "20px",
  };

  const btnStyle = {
    backgroundColor: "#c72127",
    color: "white",
    border: "none",
    borderRadius: "5px",
    marginTop: "10px",
    cursor: "pointer",
    height: "40px",
    fontSize: "12px",
    whiteSpace: "nowrap",
  };

  return (
    <div ref={ref}>
      <Card className={animationClass} style={cardStyle}>
        <CardContent style={{ flex: 1 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={image} alt={title} style={imageStyle} />
                <Typography
                  variant="h5"
                  style={{ color: "#2b4265", fontWeight: 600 }}
                >
                  {title}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" style={{ lineHeight: "25px" }}>
                {description}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions style={{ justifyContent: "left" }}>
          <Button
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            style={btnStyle}
          >
            Learn More
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

export default ModuleCard;
