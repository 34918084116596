import React, { useState, useEffect } from 'react';
import { Grid, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Intro from '../components/LandingPage/Intro';
import Overview from '../components/LandingPage/Overview';
import Modules from '../components/LandingPage/Modules';
import Ai from '../components/LandingPage/Ai';
import Widget from '../components/LandingPage/ChatbotPlugin';
import '../stylesheets/home.scss';


const Home = () => {
    const navigate = useNavigate();
    const [startAnimations, setStartAnimations] = useState(false);



    useEffect(() => {
        const timer = setTimeout(() => {
            setStartAnimations(true);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const navigateTo = (path) => {
        navigate(path);
    };

    return (
        <div className="main-content">
            <Widget />
            <div className="intro-background">
                <Container style={{ maxWidth: '1400px' }}>
                    <Grid container spacing={6} alignContent='center' justifyContent='center' style={{ minHeight: '80vh' }}>
                        <Grid className='intro' item xs={12}>
                            <Intro startAnimations={startAnimations} />
                        </Grid>
                    </Grid>
                </Container>
                <div className="white-cover"></div> 
            </div>

            <div className="overview-background">
                <Container maxWidth='lg'>
                    <Grid container spacing={6} alignContent='center' justifyContent='center' style={{ minHeight: '60vh' }}>
                        <Grid className='overview' item xs={12}>
                            <Overview />
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <div className="modules-background">
                <Container maxWidth='lg'>
                    <Grid container spacing={6} alignContent='center' justifyContent='center' style={{ minHeight: '60vh' }}>
                        <Grid className='modules' item xs={12}>
                            <Modules />
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <div className="ai-background">
                <Container maxWidth='lg'>
                    <Grid container spacing={6} >
                        <Grid className='ai' item xs={12}>
                            <Ai />
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    );
};

export default Home;