import { Routes, Route } from 'react-router-dom';
import Layout from './pages/Layout';
//import Repository from './pages/Repository';
import Home from './pages/Home';
import ExternalRedirect from './hooks/ExternalRedirect';

function MyRoutes({ startAnimations }) {
    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<Home startAnimations={startAnimations} />} />
                <Route path="/kgs" element={<ExternalRedirect to="http://kgs.ellcustomerx.com/chat/home" />} />
                <Route path="/repository" element={<Home startAnimations={startAnimations} />} />
            </Route>
        </Routes>
    );
}

export default MyRoutes;