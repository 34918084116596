import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Box,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { Link } from "react-router-dom";
import "../stylesheets/navigation.scss";
import Logo from "../assets/images/TBLDM-Logo.svg";
import JOMIS from '../assets/images/JOMIS_Logo.png'; 

function Navigation() {
  const [isTop, setIsTop] = useState(true);
  useEffect(() => {
    document.addEventListener("scroll", () => {
      const isTopNew = window.scrollY < 50;
      if (isTopNew !== isTop) {
        setIsTop(isTopNew);
      }
    });
  }, [isTop]);

  const navStyle = {
    backgroundColor: isTop ? "transparent" : "#fafbfd", 
    color: isTop ? "#fafbfd" : "#2b4265", 
    transition: "background-color 0.2s ease, color 0.2s ease"
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar style={navStyle} elevation={0}>
      <CssBaseline />
      <Toolbar className="toolbar">
        {/* Logo and Animated SVG */}
        <Box className="navbar-header">
          <a className="navbar-brand" href="/">
            <embed type="image/svg+xml" src={Logo} width="100" height="50" />
          </a>
          <img src={JOMIS} alt="JOMIS Logo" style={{ height: '50px' }} />
          <div className="pulse-line-container">
            <svg
              id="Layer_2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 592.63 144.23"
            >
              <g id="Layer_1-2">
                <polyline
                  className="cls-1"
                  points="3.5 80.17 115.9 80.17 131.36 43.44 150.69 102.72 171.95 3.5 189.99 140.73 204.81 79.52 589.13 78.88"
                />
              </g>
            </svg>
          </div>
        </Box>

        {/* Central Navigation Links */}
        <Box className="nav-links" style={{ paddingRight: '70px' }}>
          <Link className="link" to="/">
            Home
          </Link>
          <a 
            href="http://kgs.ellcustomerx.com/chat/home" 
            className="link" 
            target="_blank" 
            rel="noopener noreferrer">
            KGS
          </a>
          <Link className="link" to="/repository">
            Asset Library
          </Link>
        </Box>

        {/* Admin Button and Menu */}
        <Box className="right-section nav-links">
          <Button
            className="link"
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            Login
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{ "aria-labelledby": "basic-button" }}
          >
            <MenuItem onClick={handleClose}>Login</MenuItem>
            <MenuItem onClick={handleClose}>Register</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navigation;
