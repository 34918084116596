import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import useViewportAnimation from "../../hooks/useViewportAnimation";

const OverviewListItems = ({ text, delay }) => {
  const [ref, animationClass] = useViewportAnimation("ovFadeInRightLi", delay);

  const style = animationClass ? {} : { opacity: 0 };

  return (
    <ListItem ref={ref} className={animationClass} style={style}>
      <ListItemIcon>
        <CheckCircleIcon className="check-icon" />
      </ListItemIcon>
      <ListItemText primary={text} style={{ marginLeft: "10px" }} />
    </ListItem>
  );
};

export default OverviewListItems;
