import React from 'react';
import '../stylesheets/footer.scss'; 
import logo from '../assets/images/TBLDM-Logo.svg'; 
import jomis from '../assets/images/JOMIS_Logo.png'; 

const Footer = () => {
    return (
        <footer className="footer">
            <p>© Copyright 2023. All Rights Reserved by Ellumen.</p>
            <div className="footer_logo_container">
                <img src={logo} alt="Ellumen Logo" />
                <img src={jomis} alt="JOMIS Logo" />
            </div>
        </footer>
    );
};

export default Footer;