import React, { useState } from "react";
import { Grid, Typography, Button, Modal, Box } from "@mui/material";
import logo from "../../assets/images/TBLDM-Logo.svg";
import PlayCircleFilled from "@mui/icons-material/PlayCircleFilled";
import theme from "../../theme";
import "../../stylesheets/intro.scss";

function Intro({ startAnimations }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const videoStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "black",
    boxShadow: 24,
    padding: 0,
    outline: "none",
  };

  const btnStyle = {
    borderRadius: "50%",
    color: theme.palette.snow.main,
    backgroundColor: theme.palette.red.main,
    minWidth: "5px",
    maxWidth: "40px",
    maxHeight: "40px",
    padding: "1px",
    boxShadow: "none",
    marginLeft: "60px",
  };

  const titleStyle = {
    color: theme.palette.snow.main,
    fontSize: "55px",
    textShadow: "1px 1px 2px rgba(70, 90, 130, 0.5)",
  };

  const subtitleStyle = {
    paddingBottom: "30px",
    color: theme.palette.snow.main,
    fontSize: "20px",
    lineHeight: "40px",
    marginLeft: "30px",
    textShadow: "2px 2px 4px rgba(70, 90, 130, 0.7)",
  };

  const buttonContainerStyle = startAnimations
    ? {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        animation: "fadeIn 1s forwards",
        animationDelay: "1.2s",
        opacity: 0,
      }
    : {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        opacity: 0,
      };

  return (
    <Grid container spacing={3} className="intro-container" alignItems="center">
      <Grid item xs={12} md={6}>
        <Typography
          style={titleStyle}
          className={`animated-element ${startAnimations ? "fadeInLeft" : ""}`}
          variant="h3"
          component="h1"
          gutterBottom
        >
          Theater Blood Mobile
        </Typography>
        <Typography
          style={subtitleStyle}
          className={`animated-element ${startAnimations ? "fadeInLeft" : ""}`}
          variant="h3"
          component="h1"
          gutterBottom
        >
          The only application that specializes in theater blood management.
        </Typography>
        <div style={buttonContainerStyle}>
          <Button
            variant="contained"
            onClick={handleOpen}
            className="pulsing-button"
            style={btnStyle}
          >
            <PlayCircleFilled style={{ fontSize: 50 }} />
          </Button>
          <Typography
            style={{ color: "#fafbfd", paddingLeft: "20px" }}
            variant="button"
            display="block"
            gutterBottom
          >
            Watch the Video
          </Typography>
        </div>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        className={`animated-element ${startAnimations ? "fadeIn" : ""}`}
      >
        <img src={logo} alt="TBLD Logo" className="tbld-logo" />
      </Grid>

      <Modal open={open} onClose={handleClose} sx={{ p: 0 }}>
        <Box sx={videoStyle}>
          <video
            controls
            autoPlay
            style={{ width: "100%", outline: "none", p: 0 }}
          >
            <source src="https://customerx-resources.s3.amazonaws.com/TBLDM_12112023.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
      </Modal>
    </Grid>
  );
}

export default Intro;
