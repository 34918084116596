import React from "react";
import { Grid, Typography, List } from "@mui/material";
import monitorImg from "../../assets/images/tbld_monitor.png";
import useViewportAnimation from "../../hooks/useViewportAnimation";
import OverviewListItems from "./OverviewListItems";

function Overview() {
  const [imgRef, imgAnimationClass] = useViewportAnimation("ovFadeInLeft", 2);
  const [titleRef, titleAnimationClass] = useViewportAnimation(
    "ovFadeInRight",
    2
  );
  const listItemStaggerDelay = 200;

  return (
    <Grid container style={{ paddingLeft: "100px" }} alignItems="center">
      {/* Left Side - Image */}
      <Grid item xs={6} md={6} ref={imgRef} className={imgAnimationClass}>
        <img
          src={monitorImg}
          alt="tbld monitor"
          style={{ width: "90%", height: "90%", objectFit: "contain" }}
        />
      </Grid>

      {/* Right Side - Title, Subtitle, and List */}
      <Grid item xs={6} md={6} style={{ padding: "20px" }}>
        <div ref={titleRef} className={titleAnimationClass}>
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            style={{ color: "#2b4265", fontWeight: 700 }}
          >
            What is Theater Blood Mobile?
          </Typography>

          <Typography
            style={{ color: "#2b4265", fontWeight: 600 }}
            variant="h6"
            gutterBottom
          >
            Intuitive blood management with total visibility and custom reports.
          </Typography>
        </div>
        <List>
          {[
            "Will replace the TMDS Blood module",
            "Works in DIL environments",
            "Integrated with other JOMIS applications",
          ].map((text, index) => (
            <OverviewListItems
              key={text}
              text={text}
              delay={index * listItemStaggerDelay + 400}
            />
          ))}
        </List>
      </Grid>
    </Grid>
  );
}

export default Overview;
