import React from "react";
import { Outlet } from "react-router-dom";
import RouterListener from "../hooks/RouterListener.js"; 
import Navigation from "../components/Navigation";
import Footer from "../components/Footer.js";
import "../stylesheets/footer.scss";

export default function Layout() {
  return (
    <>
      <RouterListener /> 
      <div className="container">
        <Navigation />
        <div>
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  );
}
