import React from 'react';
import { Grid } from '@mui/material';
import ModuleCard from './ModuleCard';
import InventoryIcon from '../../assets/images/Inventory-Icon.svg';
import DonorIcon from '../../assets/images/Donor-Icon.svg';
import TransfusionIcon from '../../assets/images/Transfusion-Icon.svg';
import ReportsIcon from '../../assets/images/Reports-Icon.svg';
import AdminIcon from '../../assets/images/Admin-Icon.svg';
import '../../stylesheets/animations.scss';

const moduleData = [
  {
    title: 'Inventory',
    image: InventoryIcon,
    description: 'Manage inventory, receive and allocate blood products, and manage inter-facility shipping.'
  },
  {
    title: 'Donor',
    image: DonorIcon,
    description: 'Register, pre-screen, and qualify donors. Manage the walking blood bank and donated blood products.'
  },
  {
    title: 'Transfusion',
    image: TransfusionIcon,
    description: 'Register transfusion patients, view history, and issue blood products.'
  },
  {
    title: 'Reports',
    image: ReportsIcon,
    description: 'Generate pre-defined or custom reports, and access report history.'
  },
  {
    title: 'Admin',
    image: AdminIcon,
    description: 'Manage user access rights and equipment interfaces for local system administrators.'
  }
];

const Modules = () => {
  return (
      <Grid container spacing={1} justifyContent="space-evenly">
          {moduleData.map((module, index) => (
              <Grid item xs={12} sm={4} key={index}>
                  <ModuleCard
                      title={module.title}
                      image={module.image}
                      description={module.description}
                      animationDelay={index * 110}
                  />
              </Grid>
          ))}
      </Grid>
  );
};

export default Modules;