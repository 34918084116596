import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import aiIcon from "../../assets/images/ai_img.png";
import clickIcon from "../../assets/images/mouse_pointer.png";
import arrowIcon from "../../assets/images/arrow_up.png";
import useViewportAnimation from "../../hooks/useViewportAnimation";
import "../../stylesheets/home.scss";

const Ai = () => {
  const [ref, animationClass] = useViewportAnimation("fadeIn", 200);
  const rightSideData = [
    {
      title: "Advanced AI Implementation",
      description:
        "Rapidly analyzes incoming data and uses deep learning to continually improve results",
      image: aiIcon,
    },
    {
      title: "User-Friendly Interface",
      description:
        "Simple, clean interface and clear documentation - so no need for extra training",
      image: clickIcon,
    },
    {
      title: "Scalability and Reliability",
      description:
        "Manages large datasets, designed for all operational environments, and equipped with fail-safes for 100% uptime",
      image: arrowIcon,
    },
  ];

  const style = animationClass ? {} : { opacity: 0 };

  const btnStyle = {
    backgroundColor: "#c72127",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    height: "40px",
    fontSize: "12px",
    whiteSpace: "nowrap",
  };

  return (
    <div ref={ref} className={animationClass} style={style}>
      <Grid container spacing={16}>
        <Grid
          item
          xs={12}
          md={6}
          textAlign="center"
          style={{ marginBottom: "" }}
        >
          <Typography
            variant="h3"
            style={{
              color: "#fafbfd",
              fontSize: "38px",
              marginTop: "8%",
              marginBottom: "10%",
              marginLeft: "4%",
            }}
            gutterBottom
          >
            Our{" "}
            <span style={{ color: "#c72127" }}>
              Knowledge Generation System (KGS)
            </span>{" "}
            uses AI and real-time data processing for fast, tailored responses
          </Typography>
          <Button
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            style={btnStyle}
            component="a" 
            href="http://kgs.ellcustomerx.com/chat/home" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            Learn More
        </Button>
        </Grid>

        <Grid item xs={12} md={6}>
          {rightSideData.map((item, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              marginBottom={2}
            >
              <img
                src={item.image}
                alt={item.title}
                style={{ width: "60px", height: "60px", marginRight: "15px" }}
              />
              <div>
                <Typography
                  variant="h3"
                  style={{
                    color: "#fafbfd",
                    fontSize: "25px",
                    marginTop: "20px",
                  }}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    color: "#fafbfd",
                    fontSize: "15px",
                    lineHeight: "25px",
                  }}
                >
                  {item.description}
                </Typography>
              </div>
            </Box>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default Ai;
